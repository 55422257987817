<template>
  <div class="companyDetail-container">
    <el-drawer class="companyDetail-drawer" title="公司详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose" >
      <div v-loading="loading" element-loading-text="数据较多，拼命加载中..."  class="drawer-container">
        <div class="drawer-left">
          <div class="left-top">
            <el-form :model="editRuleForm" :rules="editRules" ref="editRuleForm" class="demo-ruleForm">
              <div class="detail-title">
                <div class="tit-info">
                    <div class="shopName-custName">
                      公司名称：{{ companyInfo.name || "--" }}
                    </div>
                </div>
              </div>
              <div class="detail-content">

                <el-form-item v-if="editParams.filedName == 'status'" class="edit-input-container editInputContainer" prop="status" label="公司状态:">
                  <el-select class="edit-input" ref="status" v-model="editRuleForm.status" placeholder="请选择" @blur="() => {editCancelBtn('status')}" @change="(e) => {editCompanyDetail('status')}" filterable>
                    <el-option v-for="(item, key) in statusSelect" :key="key" :label="item" :value="key + ''"></el-option>
                  </el-select>
                </el-form-item>
                <div @click="() => {editBtn('status', companyInfo.status)}" v-if="editParams.filedName != 'status'" class="hoverEdit">
                  公司状态:
                  <span class="editColor">{{companyInfo.status && companyInfo.status == '-1' ? '停用' : "正常"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'industry'" class="edit-input-container editInputContainer" prop="industry" label="行业类型:">
                  <el-select class="edit-input" ref="industry" v-model="editRuleForm.industry" placeholder="请选择" @blur="() => {editCancelBtn('industry')}" @change="(e) => {editCompanyDetail('industry')}" filterable>
                    <el-option v-for="(item, key) in industrySelect" :key="key" :label="item" :value="key"></el-option>
                  </el-select>
                </el-form-item>
                <div @click="() => {editBtn('industry', companyInfo.industry)}" v-if="editParams.filedName != 'industry'" class="hoverEdit">
                  行业类型:
                  <span class="editColor">{{companyInfo.industry || companyInfo.industry == '0' ? industrySelect[companyInfo.industry] : "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'scale'" class="edit-input-container" prop="scale" label="人员规模:">
                  <el-select class="edit-input" ref="scale" v-model="editRuleForm.scale" placeholder="请选择" @blur="() => {editCancelBtn('scale')}" @change="(e) => {editCompanyDetail('scale')}" filterable>
                    <el-option v-for="(item, key) in scaleSelect" :key="key" :label="item" :value="key"></el-option>
                  </el-select>
                </el-form-item>
                <div @click="() => {editBtn('scale', companyInfo.scale)}" v-if="editParams.filedName != 'scale'" class="hoverEdit">
                  人员规模:
                  <span class="editColor">{{companyInfo.scale || companyInfo.scale == '0' ? scaleSelect[companyInfo.scale] : "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'addressId'" class="edit-input-container" prop="addressId" label="省市区:">
                  <el-cascader ref="addressId" class="edit-input" placeholder="请选择" v-model="editRuleForm.addressId" @blur="() => {editCancelBtn('addressId')}" @change="(e) => {editCompanyDetail('addressId')}" :props="props" filterable></el-cascader>
                </el-form-item>
                <div @click="() => { editBtn('addressId', companyInfo.addressId)}" v-if="editParams.filedName != 'addressId'" class="hoverEdit">
                  省市区:
                  <span class="editColor">{{ companyInfo && companyInfo.areaName || '--'}}</span>
                </div>
                <el-form-item v-if="editParams.filedName == 'address'" class="edit-input-container" prop="address" label="详细地址:" >
                  <el-input ref="address" class="edit-input" placeholder="请输入内容" v-model="editRuleForm.address" @blur="() => {editCancelBtn('address')}">
                    <template slot="append" ><div @click="() => {editCompanyDetail('address')}" class="cancel">确认</div></template>
                  </el-input>
                </el-form-item>
                <div @click="() => { editBtn('address', companyInfo.address)}" v-if="editParams.filedName != 'address'" class="hoverEdit">
                  详细地址:
                  <span class="editColor">{{(companyInfo && companyInfo.address) || "--"}}</span>
                </div>
                <div class="DisableEdit">
                  创建人: {{companyInfo.create_by || '--'}}
                </div>

              </div>
            </el-form>
          </div>
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                附件
              </div>
              <div class="tip">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">上传附件<input @input="(e) => { uploadFile(e) }" class="uploadBtn-input" type="file">
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                <div class="fileItem-name">
                  <common-picture :fileUrl="item.url" :fileType="Config.fileType.imgType.includes(item.url.split('.')[item.url.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.name"></common-picture>
                </div>
                <div class="fileItem-btn-Container">
                  <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                  <el-popconfirm placement="top" title="确定删除吗？" @confirm="deleteFile(item)">
                    <el-button type="text" size="small" slot="reference">删除</el-button>
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div v-if="!fileList || !fileList.length" class="fileList-container">
              <div class="fileItem">
                暂无合同
              </div>
            </div>
          </div>
          <el-tabs class="tabsDetail" v-model="activeTab" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="1">
              <div class="data-content">
                <!-- <div class="data-title">系统信息</div> -->
                <div class="detail-content">
                  <div class="DisableEdit">
                    修改人:
                    <span class="common-clr-0a6">{{(companyInfo && companyInfo.update_by) || "--"}}</span>
                  </div>
                  <div class="DisableEdit">
                    修改人所属部门:
                    <span class="common-clr-0a6">{{(companyInfo && companyInfo.user_depart_id) || "--"}}</span>
                  </div><br/>
                  <div class="DisableEdit">
                    最后修改时间:
                    <span class="common-clr-0a6">{{(companyInfo && companyInfo.update_time) || "--"}}</span>
                  </div>
                  <div class="DisableEdit">
                    创建时间:
                    <span class="common-clr-0a6">{{(companyInfo && companyInfo.create_time) || "--"}}</span>
                  </div>
                  
                </div>
              </div>
             
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="工作记录" name="about">
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { companyDetail, editCompanyDetail, companyFile, workLog } from "../../../service/manage.js";
import { upload } from "../../../service/upload.js";
import { sysArea, sysAreaDetail } from "../../../service/common.js"
import { tableColumn, DateTransform, decimalNull, Config } from "../../../utils/index.js";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import CommonPicture from "../../../components/common/commonPicture.vue"
export default {
  props: ['drawer'],
  components: { CommonWorkLog, CommonPicture },
  data() {
    return {
      props: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node;
          let typeId
          if (level == 0) {
            typeId = 0
          } else {
            typeId = value
          }
          let resData = (await sysArea({id: typeId})).data
          let nodes = resData.map((item) => ({
              value: item.id + '',
              label: item.name,
              leaf: level >= 2
            }))
          resolve(nodes)
        }
      },
      statusSelect: {'-1': '停用', '1': '正常'},
      industrySelect: {'0': 'IT/通信/电子/互联网', '1': '金融业', '2': '商业服务', '3': '贸易/批发/零售/租赁业', '4': '文体教育/工艺美术', '5': '服务业', '6': '文化/传媒/娱乐/体育'},
      scaleSelect: {'0': '0-50', '1': '50-100', '2': '100-500', '3': '500-1000', '4': '1000以上'},
      Config,
      tableColumn,
      DateTransform,
      tableRow: {}, // 表格数据
      companyInfo: {},
      loading: false,
      fileList: [],
      orderTable: [],
      paginationOrder: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      orderParams: {
        pageNum: 1,
        pageSize: 10
      },
      serTable: [],
      paginationSer: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      serParams: {
        pageNum: 1,
        pageSize: 10
      },
      followRecordList: [], // 工作日志
      filesList: [], // 工作记录文件
      isShowInput: '',
      editRuleForm: {
        industry: '',
        scale: '',
        addressId: '',
        address: '',
      },
      editRules: { // 校验规则
        agreeSubNum: [{ validator: decimalNull, trigger: "change" }],
      },
      editParams: {
        filedName: "",
        filedValue: "",
      },
      activeName: "about", // tabs
      activeTab: "1", // tabs
      recordContent: "", // 跟进记录内容
      categoryParams: {
          pageNum: 1,
          pageSize: 10,
          name: ''
      },
    };
  },
  methods: {
    openNewWin(url) {
      window.open(url)
    },
    async companyDetail (id) { // 公司详情
        this.loading = true
        let { data } = await companyDetail({}, id)
        if (data.address_id) {
          let addressIdArr = data.address_id.split(',')
          data.areaName = (await sysAreaDetail({ id: addressIdArr[addressIdArr.length - 1] })).data
          data.addressId = addressIdArr
        } else {
          data.areaName = ''
          data.addressId = []
        }
        this.loading = false
        
        this.companyInfo = data
        if (data.file_url && data.file_name) {
          let idArr = data.file_id.split(',')
          let nameArr = data.file_name.split(',')
          let urlArr = data.file_url.split(',')
          let fileList = []
          urlArr.map((item, index) => {
            fileList.push({url: item, name: nameArr[index], id: idArr[index]})
          })
          this.fileList = fileList
        } else {
          this.fileList = []
        }
    },
    getDataInfo(row) { // 父组件传默认值
      this.tableRow = row
      console.log('--------------',row.id)
      this.companyDetail(row.id)
      this.workLog(row.id)
    },
    async deleteFile(item) { // 删除附件
       let fileList = [...this.fileList]
        let idArr = []
        let nameArr = []
        let urlArr = []
        fileList = fileList.filter((itemTwo) => {
          return itemTwo.id != item.id
        })
        fileList.map((item) => {
          idArr.push(item.id)
          nameArr.push(item.name)
          urlArr.push(item.url)
        })
        let fileParams = {
          companyId: this.tableRow.id,
          type: 1,
          updateFileUrl: item.name + ',' + item.url,
          fileId: idArr.join(','),
          fileName: nameArr.join(','),
          fileUrl: urlArr.join(',')
        }
      this.loading = true
      let { code } = await companyFile(fileParams)
      this.loading = false
      if (code == 200) {
        this.$message.success('删除成功')
        this.companyDetail(this.tableRow.id)
        this.workLog(this.tableRow.id)
      }
    },
    async uploadFile(e) { // 上传附件
        let file = e.target.files[0];
        let fileName = file.name;
        let format = fileName.split('.')[ fileName.split('.').length - 1];
        if (file.size / 1024 > 1024 * 50) {
            e.target.value = "";
            return this.$message.error('文件大小不能超过50M')
        }
        if (!Config.fileType.PWEIType.includes(format)) {
            e.target.value = "";
            return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
        }
        this.loading = true
        let resData = (await upload({ file: file })).data;
        
        let fileList = [...this.fileList]
        let idArr = [resData.id]
        let nameArr = [fileName]
        let urlArr = [resData.filename]
        fileList.map((item) => {
          idArr.push(item.id)
          nameArr.push(item.name)
          urlArr.push(item.url)
        })
        let fileParams = {
          companyId: this.tableRow.id,
          type: 0,
          updateFileUrl: fileName + ',' + resData.filename,
          fileId: idArr.join(','),
          fileName: nameArr.join(','),
          fileUrl: urlArr.join(',')
        }
        let { code } = await companyFile(fileParams)
        if (code == 200) {
          this.$message.success('上传成功')
          this.companyDetail(this.tableRow.id)
          this.workLog(this.tableRow.id)
        }
        this.loading = false
        e.target.value = ''
    },
    async editCompanyDetail(name) { // 确认-提交修改
      let params = {
        companyId: this.tableRow.id,
        type: name,
        value: this.editRuleForm[name],
        oldValue: this.companyInfo[name],
      };
      
      // if (name == 'status') {
      //   params.value = this.statusSelect[params.value]
      //   params.oldValue = this.statusSelect[params.oldValue]
      // }
      // if (name == 'industry') {
      //   params.value = this.industrySelect[params.value]
      //   params.oldValue = this.industrySelect[params.oldValue]
      // }
      // if (name == 'scale') {
      //   params.value = this.scaleSelect[params.value]
      //   params.oldValue = this.scaleSelect[params.oldValue]
      // }
      if (name == 'addressId') {
        params.value = params.value.join(',')
        params.oldValue = this.companyInfo.address_id
      }
      if (this.editParams.filedName) {
        this.$refs["editRuleForm"].validateField(name, async (error) => {
          if (!error) {
            this.loading = true
            await editCompanyDetail(params);
            // this.loading = false
            this.companyDetail(this.tableRow.id)
            this.workLog(this.tableRow.id)
            this.editParams = {
              filedName: "",
              filedValue: "",
            };
          } else {
            return false;
          }
        });
      }
        
    },
    editCancelBtn(name) { // 取消编辑
      setTimeout(() => {
        if (this.isShowInput == name) {
          this.editParams = {
            filedName: "",
            filedValue: "",
          };
          this.isShowInput = ''
        }
      }, 500)
    },
    download(url) { // 下载
      window.location.href = url
    },
    async workLog(id) { // 工作日志
      let resData = (await workLog({companyId: id})).data
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = resData
    },
    editBtn(name, value) {
      this.$refs["editRuleForm"].validate(async (valid) => {
        if (valid) {
          new Promise((resolve) => {
            this.editParams = {
              filedName: name,
              filedValue: value,
            };
            this.editRuleForm[name] = value;
            resolve()
          }).then(() => {
            if (this.$refs[name]) {
              this.$refs[name].focus()
            }
          }).then(() => {
            this.isShowInput = name
          })
        } else {
          return;
        }
      });
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    handleClick() {
      console.log(this.activeName);
    },
    loadFun(value) {
      this.$emit("loadFun", value);
    },
  },
};
</script>
<style lang="less" scoped>
.companyDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .left-top {
        display: flex;
        justify-content: space-between;
        .demo-ruleForm {
          width: 920px;
        }
      }
      .tabsDetail {
        margin-top: 24px;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890FF;
            color: #1890FF;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      .cancel {
        cursor: pointer;
      }
      /deep/ .el-input__inner,
      .el-input-group__append {
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input-group__append {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px 12px;
      .tit-info {
        display: flex;
        align-items: center;
        
        .shopName-custName {
          display: inline-block;
          display: block;
          color: #333;
          font-size: 18px;
        }
        .orderState {
          padding: 2px 8px;
          border-radius: 4px;
          border: 1px solid #F98787;
          color: #F98787;
          margin-left: 16px;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 330px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
    }
    .detail-content {
      .note-input-container {
        display: inline-block;
        width: 480px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input-container {
        display: inline-block;
        width: 280px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .editInputContainer {
        width: 280px;
        white-space: nowrap;
      }
      .edit-input {
        width: 180px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .noteHoverEdit {
        display: inline-block;
        // width: 100%;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        display: flex;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99% center
            #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
</style>
